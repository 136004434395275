<template>
  <div class="banner">
    <div class="banner__above-title">Exclusive Welcome Offer</div>
    <h1>
      <div class="banner__title">100% up to £50</div>
      <div class="banner__title banner__title--third">+ 100 spins</div>
    </h1>
    <Button
      title="Play Here"
      :target="true"
      url="https://heyspin.com/?NeoDL=Registration"
    />
    <div class="banner__desc">
      18+. New Players Only. Min. deposit £20. Max bonus allowed is £50. Offer
      duration upon receiving is valid for 2 weeks (14 days). Max Win from spins
      is £100. 35x wagering applies both for bonus and spins part. Spins are
      credited on specific games(Starburst). Spins expire after 24 hours if
      unused. Full T&Cs Apply.
    </div>
    <div class="banner__icons">
      <div>
        <img src="@/assets/images/banner/be-gamble-aware.svg" alt="BeGambleAware.org">
        <img src="@/assets/images/banner/18+.svg" alt="18+">
      </div>
      <p>Gambling can be addictive, please play responsibly.</p>
    </div>
  </div>
</template>

<script>
import Button from "./layout/Button.vue";
export default {
  name: "Banner",
  components: { Button },
};
</script>

<style lang="scss">
.banner {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 700px;
  padding: 98px 0;
  text-align: center;
  background: url("@/assets/images/banner/jpg/bg@1x.jpg");

  @media (min-resolution: 2dppx) {
    background-image: url("@/assets/images/banner/jpg/bg@2x.jpg");
  }

  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;

  @media (max-width: $size-xl) {
    height: 467px;
    padding: 50px 0;
  }

  @media (max-width: $size-lg) {
    height: 374px;
    padding: 31px 0;
  }

  @media (max-width: $size-md) {
    height: 500px;
    max-width: 320px;
    margin: 0 auto;
    padding: 157px 0 16px;
    background: url("@/assets/images/banner/png/bg-mobile@1x.png");

    @media (min-resolution: 2dppx) {
      background-image: url("@/assets/images/banner/png/bg-mobile@2x.png");
    }

    background-position: top;
    background-size: cover;
  }

  &__above-title {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 30px;
    line-height: 140%;
    text-transform: capitalize;
    color: var(--color-white);

    @media (max-width: $size-xl) {
      font-size: 20px;
    }

    @media (max-width: $size-lg) {
      font-size: 16px;
    }

    @media (max-width: $size-md) {
      font-size: 20px;
    }
  }

  &__title {
    font-size: 70px;
    font-weight: 900;
    line-height: 125%;
    font-family: "Roboto Slab";
    font-weight: 900;
    text-transform: uppercase;
    color: var(--color-yellow);

    @media (max-width: $size-xl) {
      font-size: 44px;
    }

    @media (max-width: $size-lg) {
      font-size: 34px;
    }

    @media (max-width: $size-md) {
      font-size: 34px;
    }
  }

  &__title--second {
    font-size: 42px;

    @media (max-width: $size-xl) {
      font-size: 28px;
    }

    @media (max-width: $size-lg) {
      font-size: 18px;
    }

    @media (max-width: $size-md) {
      font-size: 22px;
    }
  }

  &__title--third {
    font-size: 58px;

    @media (max-width: $size-xl) {
      font-size: 36px;
    }

    @media (max-width: $size-lg) {
      font-size: 26px;
    }

    @media (max-width: $size-md) {
      font-size: 32px;
    }
  }

  &__desc {
    font-family: "Open Sans";
    font-size: 12px;
    line-height: 140%;
    color: var(--color-grey-light);
    max-width: 364px;

    @media (max-width: $size-xl) {
      font-size: 10px;
      max-width: 226px;
    }

    @media (max-width: $size-lg) {
      font-size: 10px;
      max-width: 195px;
    }

    @media (max-width: $size-md) {
      font-size: 10px;
      max-width: 256px;
    }
  }

  &__icons {
    position: absolute;
    top: 11px;
    right: 100%;
    width: 100%;
    transform: translate(105%, 0);

    @media (min-width: $size-lg) {
      top: 84%;
      right: 2%;
      width: auto;
      transform: translate(0,0);
    }

    @media (min-width: $size-xl) {
      top: 89%;
    }

    > div {
      display: flex;
      align-items: flex-end;
      margin-bottom: 2px;

      > img {
        width: 23px;

        @media (min-width: $size-lg) {
          width: auto;
        }

        &:not(:last-child) {
          width: 164px;
          margin-right: 5px;

          @media (min-width: $size-lg) {
            width: auto;
            margin-right: 8px;
          }
        }
      }
    }

    > p {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 7px;
      line-height: 1.4;
      color: var(--color-white);
      text-align: left;

      @media (min-width: $size-lg) {
        font-size: 12px;
      }
    }
  }
}
</style>
