import Vue from "vue";
import App from "./App.vue";

import "@/assets/styles/main.scss";

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  computed: {
    linkPayload() {
      const payload = window.location.search
        ? window.location.search.slice(1)
        : null;
      return payload ? "&" + payload : "";
    },
  },
}).$mount("#app");
