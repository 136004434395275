<template>
  <div class="container">
    <div class="footer">
      <p class="footer__paragraph">
        New depositing players only. <br />
        Min. deposit £20. <br />
      </p>

      <p class="footer__paragraph">
        Welcome package includes a deposit bonus as follows: First Deposit: 100%
        up to £50 + 100 Spins on Starburst *Min. deposit £20. Winnings won with
        spins that require deposit, have to be wagered 35x. Bonuses that require
        deposit, have to be wagered 35x. This offer is available to players
        residing in United Kingdom only.
      </p>

      <p class="footer__paragraph">
        Deposits may be withdrawn before a player’s wagering requirements have
        been fulfilled. However, if this occurs, all bonuses and winnings will
        be voided/removed from the player’s account. Players must contact
        support in order to withdraw their deposit. Bets on different games
        contribute differently to the compliance of the wagering requirements:
        <a
          target="_blank"
          href="https://www.heyspin.com/#Section1"
          class="footer__paragraph--yellow"
          >Bonus Policy.</a
        >
      </p>

      <p class="footer__paragraph">
        Winnings received through the use of the deposit Spins shall not exceed
        £100. Any winnings which shall be made with the deposit Spins which
        exceeds the £100 maximum, shall be considered as nil, and shall not be
        recorded as winnings. Only the maximum winnings of £100 can be withdrawn
        by the Player subject to the terms specified in the
        <a
          href="https://www.heyspin.com/#Section1"
          target="_blank"
          class="footer__paragraph--yellow"
          >Bonus Policy</a
        >.
      </p>

      <p class="footer__paragraph">
        When a bet abuses the betting system – regardless of which game it was
        placed on – it will not count towards a player’s wagering requirements.
        If the player placed single bets equal to or greater than £4 or £0.50
        per line, or 15 percent of the bonus amount before the wagering
        requirements for the bonus have been met, we reserve the right to
        withhold any amount in excess of the player’s original deposit from a
        player’s withdrawal. In addition, if a player deposited with Skrill or
        Neteller and placed single bets equal to or greater than £2.50 of the
        bonus amount before the wagering requirements for the bonus have been
        met, we reserve the right to withhold any amount in excess of the
        player’s original deposit from a player’s withdrawal. If the player
        placed bets on any of the games which contribute 0% to the wagering
        requirement before the wagering requirements for the bonus have been
        met, we reserve the right to withhold any amount in excess of a player’s
        original deposit from a player’s withdrawal.
      </p>

      <p class="footer__paragraph">
        In Great Britain only, the games on this website are operated by AG
        Communications Limited, a Malta based company with registration number
        C48328 and having registered office at 135, High street, Sliema SLM
        1549, Malta, which is licensed and regulated by the United Kingdom
        Gambling Commission (Under account number: 39483). The current status of
        operators license can be found at:
        <a
          href="https://www.gamblingcommission.gov.uk/public-register/business/detail/39483"
          target="_blank"
          class="footer__paragraph--yellow"
          >Gambling Commission</a
        >.
      </p>

      <p class="footer__paragraph">
        <a
          href="https://www.heyspin.com/#Section1"
          target="_blank"
          class="footer__paragraph--yellow"
          >Bonus Policy</a
        >
        and
        <a
          href="https://www.heyspin.com/#Section1"
          target="_blank"
          class="footer__paragraph--yellow"
          >Terms of Service</a
        >
        apply.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
.footer {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: var(--color-grey-light);
  padding-bottom: 80px;

  @media (max-width: $size-xl) {
    padding-bottom: 40px;
  }

  @media (max-width: $size-lg) {
    padding-bottom: 47px;
  }

  @media (max-width: $size-md) {
    padding-bottom: 20px;
  }

  &__paragraph {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &__paragraph--yellow {
    color: var(--color-yellow);
  }
}
</style>
