<template>
  <div class="container">
    <div class="bonus-steps">
      <div class="bonus-steps__item" v-for="step in steps" :key="step.id">
        <img
          :src="require(`@/assets/images/bonusSteps/${step.id}.svg`)"
          :alt="step.title"
          class="bonus-steps__image"
        />
        <div class="bonus-steps__content">
          <span class="bonus-steps__title">{{ step.title }}</span>
          <span class="bonus-steps__desc" v-html="step.htmlContent"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BonusSteps",
  data() {
    return {
      steps: [
        {
          id: 1,
          title: "Register",
          htmlContent: "For an account",
        },
        {
          id: 2,
          title: "Deposite",
          htmlContent: "It’s Safe and Easy",
        },
        {
          id: 3,
          title: "Get Bonus",
          htmlContent:
            'up to <span class="bonus-steps__desc--yellow">£50</span> + 100 <span class="bonus-steps__desc--yellow">Spins!</span>',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.bonus-steps {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 80px 0;

  @media (max-width: $size-md) {
    flex-flow: column;
    padding: 20px 32px 20px 53px;
  }

  &__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    @media (max-width: $size-md) {
      margin-bottom: 20px;
    }
  }

  &__image {
    width: 72px;

    @media (max-width: $size-xl) {
      width: 46px;
    }

    @media (max-width: $size-md) {
      width: 28px;
    }
  }

  &__content {
    display: flex;
    flex-flow: column;
    margin-left: 48px;
    font-family: "Open Sans";
    font-style: normal;
    line-height: 140%;
    color: var(--color-white);

    @media (max-width: $size-xl) {
      margin-left: 31px;
    }
  }

  &__title {
    font-size: 30px;
    margin-bottom: 10px;

    @media (max-width: $size-xl) {
      font-size: 18px;
    }

    @media (max-width: $size-lg) {
      font-size: 18px;
    }
  }

  &__desc {
    font-size: 24px;
    white-space: nowrap;

    @media (max-width: $size-xl) {
      font-size: 14px;
    }
  }

  &__desc--yellow {
    color: var(--color-yellow);
  }
}
</style>
