<template>
  <div class="container">
    <div class="game-cards">
      <h2 class="game-cards__title">Our Top Games</h2>
      <div class="game-cards__desc">
        We have the latest games from all the top providers, including
        Microgaming, NetEnt and WMS.
      </div>
      <div class="game-cards__list">
        <GameCardsItem v-for="card in cards" :card="card" :key="card.key" />
      </div>
    </div>
  </div>
</template>

<script>
import GameCardsItem from "./GameCardsItem.vue";

export default {
  name: "GameCards",
  components: { GameCardsItem },
  data() {
    return {
      cards: [
        {
          id: 1,
          title: "Slot Games",
          desc: "Spin over 350 popular slot games including Rainbow Riches, Starburst, Fruity Friends and more.",
          src: "slot-games",
        },
        {
          id: 2,
          title: "Live Dealers",
          desc: "Feel the thrill of live action and enjoy Live Casino games on your PC and mobile devices.",
          src: "live-dealers",
        },
        {
          id: 3,
          title: "Table Games",
          desc: "Stay in control with Heyspin’s selection of Table Games including card games and roulette.",
          src: "table-games",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.game-cards {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 80px;

    @media (max-width: $size-md) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  &__title {
    font-family: "Roboto Slab";
    font-weight: 700;
    font-size: 46px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: var(--color-white);
    margin-bottom: 20px;

    @media (max-width: $size-xl) {
      font-size: 26px;
    }
  }

  &__desc {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: var(--color-grey-light);
    max-width: 536px;
    margin-bottom: 60px;

    @media (max-width: $size-xl) {
      font-size: 14px;
      margin-bottom: 30px;
    }
    @media (max-width: $size-md) {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
}
</style>
